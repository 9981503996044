import { createGlobalStyle } from 'styled-components';
import { CSS_VARIABLE_NAMES, SIDEMENU_WIDTHES } from '@constants/style';
import { COLORS } from '@constants/colors';


export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;

    scrollbar-color: ${COLORS.greyLight} ${COLORS.white};
  }

  :root {
    ${CSS_VARIABLE_NAMES.menuWidth}: ${SIDEMENU_WIDTHES.open};
  }
`;
